import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './MissionVision.css'; // Custom CSS file

const MissionVision = () => {
  return (
    <div className='mission-vision-section'>
      <Container>
        <Row>
          <Col md='5' className='mission-section'>
            <h3 className='section-heading'>Our Mission</h3>
            <div className='heading-underline'></div>
            <p className='section-text'>
            Our mission in Identity and Access Management (IAM) is to ensure the security, efficiency, and accessibility of digital resources by managing user identities and their access rights.
            </p>
          </Col>
          <Col md='2'></Col>
          <Col md='5' className='vision-section'>
            <h3 className='section-heading'>Our Vision</h3>
            <div className='heading-underline'></div>
            <p className='section-text'>
            Our vision for Identity and Access Management (IAM) is to create a secure, seamless, and user-centric digital environment where every interaction is protected and efficient.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MissionVision;
