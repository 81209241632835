import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './AboutUsBanner.css'; // Import the CSS file

const AboutUsBanner = () => {
  return (
    <Container className='simplify-management-section text-center'>
      <Row>
        <Col>
          <h2 className='section-title'>
              Revolutionizing Your Digital Experience
          </h2>
          <hr className='title-divider' />
          <p className='section-subtitle'>
            SVK Limited is a leading company specializing in Identity and Access Management (IAM) and Privileged Access Management (PAM) solutions            
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUsBanner;
