import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import './CustomersHighlight.css'; // Import the CSS file

const CustomersHighlight = () => {
  return (
    <div className='customer-highlight-companies-section'>
      <Row className='justify-content-center text-center'>
        <Col md='12'>
          <h2 className='section-title'>
            Trusted by World's Leading Companies
          </h2>
        </Col>
      </Row>
      <Row className='logo-row justify-content-center'>
        <Col xs='6' sm='4' md='2' className='logo-col'>
          <img
            src='asserts/images/TFL.jpeg'
            alt='TFL'
            className='company-logo'
          />
        </Col>
        <Col xs='6' sm='4' md='2' className='logo-col'>
          <img
            src='asserts/images/UKHO.jpeg'
            alt=''
            className='UK Homeoffice'
          />
        </Col>
        <Col xs='6' sm='4' md='2' className='logo-col'>
          <img
            src='asserts/images/ONS.jpeg'
            alt=''
            className='ONS'
          />
        </Col>
        
      </Row>
      <Row className='logo-row justify-content-center'>
      <Col xs='6' sm='4' md='2' className='logo-col'>
          <img
            src='asserts/images/Hackney.jpeg'
            alt=''
            className='Hackney'
          />
        </Col>
        <Col xs='6' sm='4' md='2' className='logo-col'>
          <img
            src='asserts/images/Deutsche_Bank.jpeg'
            alt=''
            className='Deutsche Bank'
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomersHighlight;
