import React from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import './ContactForm.css'; // Import the CSS file

const ContactForm = () => {
  return (
    <div className='contact-section'>
      <Row>
        {/* Image Section */}
        <Col md='6' className='contact-image'>
          <img
            src='asserts/images/contactus.jpeg' // Replace with actual image path
            alt='Business Meeting'
            className='img-fluid'
          />
        </Col>

        {/* Form Section */}
        <Col md='6' className='contact-form p-4'>
          <h2 className='form-heading'>Get In Touch</h2>
          <div className='form-underline'></div>
          <Form>
            <FormGroup>
              <Label for='firstName'>First name*</Label>
              <Input
                type='text'
                name='firstName'
                id='firstName'
                placeholder='Enter your first name'
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='lastName'>Last name</Label>
              <Input
                type='text'
                name='lastName'
                id='lastName'
                placeholder='Enter your last name'
              />
            </FormGroup>
            <FormGroup>
              <Label for='email'>Email*</Label>
              <Input
                type='email'
                name='email'
                id='email'
                placeholder='Enter your email'
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='message'>Message</Label>
              <Input
                type='textarea'
                name='message'
                id='message'
                rows='4'
                placeholder='Enter your message'
              />
            </FormGroup>

            {/* Submit Button */}
            <Button color='primary' className='w-100'>
              SUBMIT
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ContactForm;
