import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ContactForm from '../../components/ContactForm/ContactForm';

import './ContactUs.css';

const ContactUs = () => (
  <div>
    <Row className='contact-section'>
      <Col>
        <ContactForm />
      </Col>
    </Row>
  </div>
);

export default ContactUs;
