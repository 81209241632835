import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import HomeBanner from '../../components/HomeBanner/HomeBanner';
import CustomersHighlight from '../../components/CustomersHighlight/CustomersHighlight';
import Services from '../../components/Services/Services';

import './Home.css';

const Home = () => (
  <div className='home-section'>
    <Row className='home-banner-row'>
      <Col className='bg-light border'>
        <HomeBanner />
      </Col>
    </Row>
    <Row className='home-services-row'>
      <Col>
        <Services />
      </Col>
    </Row>
    <Row className='home-customer-highlight-row'>
      <Col className='home-customer-highlight-border'>
        <CustomersHighlight />
      </Col>
    </Row>
  </div>
);

export default Home;
