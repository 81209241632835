import * as React from 'react';
import { Row, Col, Button } from 'reactstrap';

import './HomeBanner.css'; // Importing CSS file

const HomeBanner = () => (
  <div className='hero-section device-image' >
    <Row className='justify-content-center'>
      <Col md='8'>
        <h6 className='effortless-title'>
            Identity and Access management
        </h6>
        <h1 className='main-title'>
           Identity Trust
        </h1>
        <p className='sub-title'>
        Implement a Zero Trust approach with our advanced IAM platform, ensuring user authentication, authorization, and access control for the appropriate resources at all times and locations.
        </p>
       
        <div className='button-group'>
          <Button color='primary' className='btn-get-started'>
            Get Started
          </Button>
          <Button outline color='primary' className='btn-schedule-demo'>
            Schedule a Demo
          </Button>
        </div>
      </Col>
    </Row>
  </div>
);

export default HomeBanner;
