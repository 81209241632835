import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Identity from '../../components/Identity/IdentityIAM';

import './IdentityDetail.css';

const IdentityDetail = () => (
  <div>
    Test
    <Row className='about-us-banner-section'>
      <Col className='about-us-banner-col bg-light border'>
        <Identity />
      </Col>
    </Row>    
  </div>
);

export default IdentityDetail;
