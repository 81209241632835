import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from 'reactstrap';

import './WhoWeAre.css'; // // Custom CSS file

const WhoWeAre = () => {
  return (
    <Container className='who-we-are-section'>
      <Row>
        <Col md='12'>
          <h2 className='section-title'>WHO WE ARE</h2>
        </Col>
      </Row>
      <Row>
        {/* Left column with image */}
        <Col md='6' className='image-col'>
          <Card className='image-card'>
            <img
              src='asserts/images/th.jpeg'
              alt='Team meeting at the office'
              className='company-image'
            />
          </Card>
        </Col>

        {/* Right column with text */}
        <Col md='6' className='text-col'>
          <Card className='text-card'>
            <CardBody>
              <CardTitle className='text-title'>Meet the Company</CardTitle>
              <CardText className='text-description'>
              SVK Limited  provides a platform offering a multitude of services to
                fortify your cybersecurity perimeter, delivered and continuously
                managed by our highly trained experts, enabling you to focus on
                the main aspects of your business.
              </CardText>
            </CardBody>
          </Card>
          <Card className='text-card'>
            <CardBody>
              <CardTitle className='text-title'>Company Mission</CardTitle>
              <CardText className='text-description'>
              SVK Limited's mission is to provide world-class service while
                simultaneously striving to be the premier workplace.
              </CardText>
            </CardBody>
          </Card>
          <Card className='text-card'>
            <CardBody>
              <CardTitle className='text-title'>Our History</CardTitle>
              <CardText className='text-description'>
                In 2015, SVK Limited  opened its doors, initially catering to a sole
                customer in the UK. Since that pivotal moment, our footprint has
                expanded globally, with established presences in the
                Netherlands, Australia, and South Africa. Our clientele has
                undergone remarkable growth, spanning diverse sectors on a
                global scale. Beyond the expansion of our client base, our
                workforce has consistently flourished, marking year-on-year
                achievements in employee growth.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WhoWeAre;
