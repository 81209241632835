import * as React from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import './NavMenu.css'; // Custom CSS file

export default class NavMenu extends React.PureComponent<
  {},
  { isOpen: boolean }
> {
  public state = {
    isOpen: false,
  };

  public render() {
    return (
      <header>
        <Navbar
          className='navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3'
          light
        >
          <Container>
            <NavbarBrand tag={Link} to='/'>
              <img src="asserts/images/logo.PNG" alt="Company Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} className='mr-2' />
            <Collapse
              className='d-sm-inline-flex flex-sm-row-reverse'
              isOpen={this.state.isOpen}
              navbar
            >
              <ul className='navbar-nav flex-grow'>
                <NavItem>
                  <NavLink tag={Link} className='text-dark' to='/'>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className='text-dark' to='/about-us'>
                    About us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className='text-dark' to='/contact-us'>
                    Contact Us
                  </NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}
