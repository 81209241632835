import React from 'react';

import './Footer.css'; // Importing CSS file

const Footer = () => {
  return (
    <footer className='footer-distributed'>
      <div className='footer-left'>
        <h3>
          <img
            className='footer-logo'
            src='asserts/images/logo.jpeg'
            alt='Company Logo'
          />
        </h3>

        <p className='footer-links'>
          <a href='#' className='link-1'>
            Home
          </a>
          <a href='#'>About us</a>
          <a href='#'>Contact us</a>
        </p>

        <p className='footer-company-name'>SVK Limited © 2015</p>
      </div>

      <div className='footer-center'>
        <div>
          <i className='fa fa-map-marker'></i>
          <p>
            <span>Vista Centre</span> 50 Salisbury Road, Hounslow, TW4 6JQ
          </p>
        </div>
        <div>
          <i className='fa fa-registered'></i>
          <p>08146134</p>
        </div>

        <div>
          <i className='fa fa-phone'></i>
          <p>+44 7972 888038</p>
        </div>

        <div>
          <i className='fa fa-envelope'></i>
          <p>
            <a href='mailto:vinothkummar@svklimited.com'>vinothkummar@svklimited.com</a>
          </p>
        </div>
      </div>

      <div className='footer-right'>
        <p className='footer-company-about'>
          <span>About the company</span>
           SVK Limited revolutionize the tech industry with cutting-edge solutions that drive progress and enhance everyday life. Our commitment to innovation and excellence ensures we stay ahead in a rapidly evolving market.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
