import * as React from 'react';
import { Route } from 'react-router-dom';
import Layout from './Layouts/Layout';
import Home from './Layouts/Home/Home';
import ContactUs from './Layouts/ContactUs/ContactUs';
import AboutUs from './Layouts/AboutUs/AboutUs';
import IdentityDetail from './Layouts/IdentityDetail/IdentityDetail';

import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/identity-detail' component={IdentityDetail} />
    </Layout>
);
