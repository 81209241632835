import * as React from 'react';
import NavMenu from '../components/NavMenu/NavMenu';
import Footer from '../components/Footer/Footer';

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <NavMenu />
    {props.children}
    <Footer />
  </React.Fragment>
);
