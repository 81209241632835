import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './Identity.css'; // Custom CSS file

const IdentityIAM = () => (
    <div>
    <Row className='about-us-banner-section'>
      <Col className='about-us-banner-col bg-light border'>
        <Container className='simplify-management-section'>
            <Row>
                <Col>
                  
                    <h2 className='identity-title'>Empower Your Security with Comprehensive IAM Solutions</h2>
                    <h4 className='identity-title'>Introduction</h4>
                    <p>In today’s digital landscape, protecting sensitive information is paramount. Our Identity and Access Management (IAM) solutions provide the tools you need to secure your organization, streamline user access, and ensure compliance.</p>
                    <h4 className='identity-title'>Why IAM Matters</h4>
                    
                    <h6 className='identity-title'>
                    Protect Your Organization</h6>
                    
                    <p className='margin-left20'>Safeguard against unauthorized access to critical resources.
                    Reduce the risk of data breaches with robust security measures.</p>
                    
                    <h6 className='identity-title'>
                    Enhance User Experience</h6>
                    <p className='margin-left20'>Simplify access for users with single sign-on (SSO) capabilities.
                    Improve productivity by minimizing login frustrations.</p>
                    <h6 className='identity-title'>
                    Ensure Compliance</h6>
                    <p className='margin-left20'>Meet regulatory requirements with comprehensive auditing and reporting.
                    Maintain governance standards through controlled access to sensitive data.</p>    
                    <h4 className='identity-title'>Key Features of Our IAM Solutions</h4>                    
                    <h6 className='identity-title'>
                    Identity Management</h6>                    
                    <p className='margin-left20'>Centralized management of user identities across your organization.
                    Automated provisioning and de-provisioning of user accounts.</p>  
                    <h6 className='identity-title'>
                    Authentication</h6>                    
                    <p className='margin-left20'>Multi-factor authentication (MFA) for added security.
                    Support for various authentication methods, including biometrics and tokens.</p>  
                    <h6 className='identity-title'>
                    Authorization</h6>                    
                    <p className='margin-left20'>Fine-grained access controls to ensure users only see what they need.
                    Role-based access management to simplify permissions.</p>  
                    <h6 className='identity-title'>
                    Accounting</h6>                    
                    <p className='margin-left20'>Detailed logging and monitoring of user activity for transparency.
                    Tools for auditing and compliance to help you stay on top of regulations.</p>  
                    <h4 className='identity-title'>Benefits of Implementing IAM</h4>   
                    <h6 className='identity-title'>
                    Streamlined Operations:</h6>                    
                    <p className='margin-left20'>Reduce the administrative burden with automated processes.
                    Improve operational efficiency through streamlined access management.</p>  
                    <h6 className='identity-title'>
                    Enhanced Security Posture</h6>                    
                    <p className='margin-left20'>Minimize vulnerabilities with proactive identity management.
                    Quickly respond to potential security threats with real-time monitoring.</p>   
                    <h6 className='identity-title'>
                    Scalability and Flexibility</h6>                    
                    <p className='margin-left20'>Easily scale IAM solutions to meet your organization’s growth.
                    Customize access controls to fit the unique needs of your business.</p>    
                    <h4 className='identity-title'>Get Started Today</h4>   
                    <h6 className='identity-title'>
                    Call to Action</h6>                    
                    <p className='margin-left20'>Ready to transform your security strategy with our IAM solutions? Contact us today for a demo or consultation, and see how we can help protect your organization and enhance user experience.</p>            
                </Col>
            </Row>
        </Container>
      </Col>
    </Row>    
  </div>
);

export default IdentityIAM;
