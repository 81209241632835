import React from 'react';
import { Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import Marquee from 'react-fast-marquee';

import './Services.css'; // Custom CSS file

const Services = () => {
  return (
    <div className='image-section'>

      <h2>What We Do</h2>
      <p>
        We offer a range of innovative solutions tailored to meet the demands of
        your business. Our services span across device management, security, and
        IT consulting, ensuring that your operations are seamless and secure.
      </p>
      <div className='image-row'>
        {/* Service 1np: Mobile Device Management */}
        <Card className='image-card bg-light border'>
          <CardImg
            top
            width='100%'
            src='asserts/images/thump.jpg'
            alt='Identity Management'
          />
          <CardBody>
            <CardTitle tag='h3'>Identity and Access Management (IAM)</CardTitle>
            <CardText>
              Identity and access management (IAM) is a framework of business processes, policies and technologies that facilitates the management of electronic or digital identities.
              <br />
              <a href='identity-detail'>Explore →</a> 
            </CardText>
          </CardBody>
        </Card>

        {/* Service 2: Kiosk Lockdown Software */}
        <Card className='image-card bg-light border'>
          <CardImg
            top
            width='100%'
            src='asserts/images/apim.png'
            alt='APIM Management'
          />
          <CardBody>
            <CardTitle tag='h3'>API Management</CardTitle>
            <CardText>
              API Management is a hybrid, multicloud management platform for APIs across all environments. It supports the complete API lifecycle as a platform-as-a-service.              <br />
             {/*<br />
              <a href='#'>Explore →</a> */}
            </CardText>
          </CardBody>
        </Card>

        {/* Service 3: BYOD Management */}
        <Card className='image-card bg-light border'>
          <CardImg
            top
            width='100%'
            src='asserts/images/Legacy-page-banner.jpg'
            alt='Legacy Application Modernization'
          />
          <CardBody>
            <CardTitle tag='h3'>Legacy Application Modernization</CardTitle>
            <CardText>
            egacy application modernization is the process of upgrading or transforming outdated systems into more contemporary, efficient, and adaptable solutions.
              {/*<br />
              <a href='#'>Explore →</a> */}
            </CardText>
          </CardBody>
        </Card>

        {/* Service 4: Cloud Solutions */}
        <Card className='image-card bg-light border'>
          <CardImg
            top
            width='100%'
            src='asserts/images//CloudSolutions.png'
            alt='Cloud Solutions'
          />
          <CardBody>
            <CardTitle tag='h3'>Cloud Solutions</CardTitle>
            <CardText>
              Scale your business with our cloud services that provide
              flexibility, scalability, and security. We offer private, public,
              and hybrid cloud solutions tailored to your needs.
              <br />
              {/*<a href='#'>Explore →</a>*/}
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Services;
