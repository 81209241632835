import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import AboutUsBanner from '../../components/AboutUsBanner/AboutUsBanner';
import WhoWeAre from '../../components/WhoWeAre/WhoWeAre';
import MissionVision from '../../components/MissionVision/MissionVision';

import './AboutUs.css';

const AboutUs = () => (
  <div>
    <Row className='about-us-banner-section'>
      <Col className='about-us-banner-col bg-light border'>
        <AboutUsBanner />
      </Col>
    </Row>
    <Row className='who-we-are-main-section'>
      <Col>
        <WhoWeAre />
      </Col>
    </Row>
    <Row className='mission-vision-section bg-light'>
      <Col className='mission-vision-col'>
        <MissionVision />
      </Col>
    </Row>
  </div>
);

export default AboutUs;
